import React, { useContext, useEffect, useState } from "react";
import { useRef } from "react";
import { MainContext } from "../App";

function ProductNotes() {
	const { setPopupVisible, cart, setCart, popupData } = useContext(MainContext);
	const notesRef = useRef();

	const saveNotes = (e) => {
		console.log("saveNotes");
		e.preventDefault();
		let tempCart = { ...cart };
		tempCart.products[popupData.index].notes = notesRef.current.value;
		setCart(tempCart);
		setPopupVisible(false);
	};

	return (
		<div className="popup popup-small">
			<form className="container" onSubmit={saveNotes}>
				<header>
					<div className="buttons-group">
						<button
							className="btn btn-tertiary btn-square"
							onClick={() => setPopupVisible(false)}
						>
							<i className="fas fa-times"></i>
						</button>
						<div>Note prodotto {popupData.name}</div>
					</div>
				</header>
				<div className="content">
					<textarea ref={notesRef} defaultValue={cart?.products[popupData.index].notes} />
				</div>
				<button className="btn btn-primary btn-block">
					<div>
						<div>Salva</div>
					</div>
				</button>
			</form>
		</div>
	);
}

export default ProductNotes;
