import React, { useContext, useRef } from "react";
import { MainContext } from "../App";
import Api from "./api/api";
import { jwtDecode } from "jwt-decode";

function Login() {
	const { setLoading, setToast, setUser } = useContext(MainContext);
	const emailRef = useRef();
	const passwordRef = useRef();

	const handleSubmit = async (e) => {
		e.preventDefault();

		const request = {
			email: emailRef.current.value,
			password: passwordRef.current.value,
		};
		setLoading(true);
		const login = await Api.login(request);
		setLoading(false);
		if (login.success == 0) setToast(login.error);
		else {
			const decoded = jwtDecode(login.token);
			localStorage.setItem("waiter-user", login.token);
			const response = await Api.getProtected("/users/get/" + decoded.user_id + "/");
			if (response.success == 0) alert(response.error);
			else setUser(response.data);
		}
	};

	return (
		<div className="login">
			<form className="content" onSubmit={handleSubmit}>
				<h2>Login</h2>
				<div className="form-group">
					<label>Email:</label>
					<input type="email" ref={emailRef} autoComplete="username" required />
				</div>
				<div className="form-group">
					<label>Password:</label>
					<input
						type="password"
						ref={passwordRef}
						autoComplete="current-password"
						required
					/>
				</div>
				<button type="submit" className="btn btn-primary btn-block">
					Accedi
				</button>
			</form>
		</div>
	);
}

export default Login;
