import React, { useContext } from "react";
import { MainContext } from "../App";
import Allergens from "./Allergens";

function Product() {
	const { handleAddProduct, productSheet, setProductSheet } = useContext(MainContext);

	return (
		<div className="popup lg">
			<div className="container">
				<header>
					<div className="buttons-group">
						<button
							className="btn btn-tertiary btn-square"
							onClick={() => setProductSheet(false)}
						>
							<i className="fas fa-times"></i>
						</button>
						<div>{productSheet.name}</div>
					</div>
				</header>
				<div className="content product-sheet">
					{productSheet.img && (
						<div className="img-box">
							<img src={productSheet.img} />
						</div>
					)}
					{productSheet.description && (
						<div className="text-box small">{productSheet.description}</div>
					)}
					<div className="info-box">
						<div>Prezzo</div>
						<div>{productSheet.price}</div>
					</div>
					{productSheet.ingredients.length > 0 && (
						<div className="color-box list-ingredients">
							<div className="title">Ingredienti</div>
							<div className="small">
								{productSheet.ingredients.map((ingredient, i) => (
									<span key={i} >
										{ingredient.name}
									</span>
								))}
							</div>
						</div>
					)}
					{productSheet.allergens.length > 0 && (
						<div className="color-box">
							<div className="title">Allergeni</div>
							<div className="allergens">
								<Allergens allergens={productSheet.allergens} />
							</div>
						</div>
					)}
				</div>
				<div className="buttons-group">
					<button
						onClick={() => handleAddProduct(productSheet)}
						className="btn btn-primary btn-block"
					>
						Aggiungi
					</button>
				</div>
			</div>
		</div>
	);
}

export default Product;
