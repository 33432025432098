import React, { useContext } from "react";
import { MainContext } from "../App";

function CategoriesSelect() {
	const { category, setCategory, courses } = useContext(MainContext);

	if (!courses) return;
	return (
		<div className="horizzontal-menu">
			<div className="scroll-content">
				{courses.map((course, i) =>
					course.categories.map((c, i) => (
						<div key={i}>
							<button
								onClick={() => {
									setCategory(c);
								}}
								className={"button " + (c.id == category.id ? "active" : "")}
							>
								{c.name}
							</button>
						</div>
					))
				)}
			</div>
		</div>
	);
}

export default CategoriesSelect;
