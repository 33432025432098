import React, { useContext, useEffect, useState } from "react";
import { useRef } from "react";
import { MainContext } from "../App";

function OrderNotes() {
	const { setPopupVisible, cart, setCart } = useContext(MainContext);
	const notesRef = useRef();

	const saveNotes = (e) => {
		console.log("saveNotes");
		e.preventDefault();
		let tempCart = { ...cart };
		tempCart.notes = notesRef.current.value;
		setCart(tempCart);
		setPopupVisible(false);
	};

	return (
		<div className="popup popup-small">
			<form className="container order-notes" onSubmit={saveNotes}>
				<header>
					<div className="buttons-group">
						<button
							className="btn btn-tertiary btn-square"
							onClick={() => setPopupVisible(false)}
						>
							<i className="fas fa-times"></i>
						</button>
						<div>Note ordine</div>
					</div>
				</header>
				<div className="content">
					<textarea ref={notesRef} defaultValue={cart?.notes} />
				</div>
				<div className="footer">
					<button className="btn btn-primary btn-block">
						<div>
							<div>Salva</div>
						</div>
					</button>
				</div>
			</form>
		</div>
	);
}

export default OrderNotes;
