import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../App";
import Api from "./api/api";

function Toast() {
	const { toast, setToast } = useContext(MainContext);
	const [classes, setClasses] = useState("toast fade hide");

	useEffect(() => {
		animate();
	}, [toast]);

	const animate = () => {
		console.log("animate");
		if (toast) {
			setClasses("toast fade");
			setTimeout(() => setClasses("toast fade hide"), 1500);
			setTimeout(() => setToast(false), 1700);
		}
	};

	return (
		<div className={classes}>
			<div className="content">{toast}</div>
		</div>
	);
}

export default Toast;
