import { useContext, useState } from "react";
import { MainContext } from "../App";
import Api from "./api/api";
import functions from "../utilities/functions";

function Covers() {
	const {
		user,
		setPopupVisible,
		lists,
		setAlertData,
		setLoading,
		setTable,
		setFlow,
		setCart,
		tempTable,
		ordersDisabled,
		menu,
		shop,
		setTableEditPopup,
	} = useContext(MainContext);
	const [selectedCovers, setSelectedCovers] = useState(0);
	const [list, setList] = useState(null);
	const [splittedCovers, setSplittedCovers] = useState([]);

	const handleClick = (covers) => {
		setSelectedCovers(covers);
	};

	const handleSubmit = async () => {
		setLoading(true);
		const response = await Api.postProtected("/tables/edit/" + tempTable.id + "/", {
			status: 1,
			user_id: user.id,
			covers: selectedCovers,
			list_id: list ? list.id : null,
			splitted_covers: splittedCovers.length > 0 ? splittedCovers : null,
		});
		if (response.success == 0) {
			alert(response.error);
			setLoading(false);
		} else {
			setPopupVisible(false);
			setTableEditPopup(true);
		}
	};

	const incrementSplittedCovers = (id) => {
		const index = splittedCovers.findIndex((el) => el.id == id);
		if (index > -1) {
			const newData = [...splittedCovers];
			newData[index].qty++;
			setSplittedCovers(newData);
		} else {
			setSplittedCovers([...splittedCovers, { id: id, qty: 1 }]);
		}
	};

	const decrementSplittedCovers = (id) => {
		const index = splittedCovers.findIndex((el) => el.id == id);
		if (index > -1) {
			if (splittedCovers[index].qty == 0) return;
			const newData = [...splittedCovers];
			newData[index].qty--;
			setSplittedCovers(newData);
		} else {
			setSplittedCovers([...splittedCovers, { id: id, qty: 1 }]);
		}
	};

	const getSplittedCovers = (id) => {
		const el = splittedCovers.find((el) => el.id == id);
		if (el) return el.qty;
		else return 0;
	};

	const getFilteredProducts = (filter) => {
		const res = [];
		for (const course of menu) {
			for (const category of course.categories) {
				for (const product of category.products) {
					if (product[filter] == 1) {
						if (list) {
							const priceList = product.prices.find(
								(p) => p.list_id == list.id && p.price
							);
							const assortment = product.assortment.find(
								(el) =>
									el.shop_id == shop.id && el.list_id == list.id && el.active == 1
							);
							console.log("priceList", priceList);
							if (priceList && assortment)
								res.push({ ...product, price: priceList.price });
						} else res.push(product);
					}
				}
			}
		}
		console.log("getFilteredProducts", filter, res);
		return res;
	};

	return (
		<div className="popup md">
			<div className="container covers-table">
				<header>
					<div className="buttons-group">
						<button
							className="btn btn-tertiary btn-square"
							onClick={() => setPopupVisible(false)}
						>
							<i className="fas fa-times"></i>
						</button>
						<div>
							<span>Apertura</span>
							<div className="name-open-table">
								{tempTable.name}{" "}
								<span className="extrasmall">{tempTable.room.name}</span>
							</div>
						</div>
					</div>
				</header>
				<div className="content">
					<div className="boxed-section">
						<p className="text-desc">Scegli il listino:</p>
						<div className="lists-container">
							<button
								className={"btn" + (!list ? " btn-secondary" : " btn-tertiary")}
								onClick={() => setList(null)}
							>
								Base
							</button>
							{lists.map((l, i) => (
								<button
									key={i}
									className={
										"btn" +
										(list && l.id == list.id
											? " btn-secondary"
											: " btn-tertiary")
									}
									onClick={() => setList(l)}
								>
									{l.name}
								</button>
							))}
						</div>
					</div>

					{(!list || list.allyoucaneat == 0) && (
						<div className="boxed-section">
							<p className="text-desc">Scegli il numero di coperti:</p>
							<div className="plus-minus">
								<button
									onClick={() =>
										handleClick(selectedCovers > 0 ? selectedCovers - 1 : 0)
									}
								>
									<i className="fas fa-minus"></i>
								</button>
								<input
									type="number"
									value={selectedCovers}
									onChange={(event) => handleClick(parseInt(event.target.value))}
								/>
								<button onClick={() => handleClick(selectedCovers + 1)}>
									<i className="fas fa-plus"></i>
								</button>
							</div>
						</div>
					)}
					{list && list.allyoucaneat == 1 && (
						<div className="boxed-section">
							<div className="group vertical">
								<p className="text-desc">Scegli i menu:</p>
								{getFilteredProducts("is_selfordering_menu").map((product, i) => (
									<div key={i} className="product-line">
										<div>{product.name}</div>
										<div className="plus_minus_container">
											{functions.formatter.format(product.price)}
											<button
												className="btn btn-primary btn-square"
												onClick={() => decrementSplittedCovers(product.id)}
											>
												<i className="fas fa-minus" aria-hidden="true"></i>
											</button>
											<div>{getSplittedCovers(product.id)}</div>
											<button
												className="btn btn-primary btn-square"
												onClick={() => incrementSplittedCovers(product.id)}
											>
												<i className="fas fa-plus" aria-hidden="true"></i>
											</button>
										</div>
									</div>
								))}
							</div>
						</div>
					)}
					{getFilteredProducts("rapid_choice").length > 0 && (
						<div className="boxed-section">
							<div className="group vertical">
								<p className="text-desc">
									Scelta rapida prodotti:
									<span className="text-desc-small">
										Aggiungi in apertura del tavolo i seguenti prodotti:
									</span>
								</p>
								{getFilteredProducts("rapid_choice").map((product, i) => (
									<div key={i} className="product-line">
										<div>{product.name}</div>
										<div className="plus_minus_container">
											{functions.formatter.format(product.price)}
											<button
												className="btn btn-primary btn-square"
												onClick={() => decrementSplittedCovers(product.id)}
											>
												<i className="fas fa-minus" aria-hidden="true"></i>
											</button>
											<div>{getSplittedCovers(product.id)}</div>
											<button
												className="btn btn-primary btn-square"
												onClick={() => incrementSplittedCovers(product.id)}
											>
												<i className="fas fa-plus" aria-hidden="true"></i>
											</button>
										</div>
									</div>
								))}
							</div>
						</div>
					)}
				</div>
				<div>
					<button className="btn btn-primary btn-block" onClick={handleSubmit}>
						Apri Tavolo
					</button>
				</div>
			</div>
		</div>
	);
}

export default Covers;
