import img1 from "../images/allergens/1.png";
import img2 from "../images/allergens/2.png";
import img3 from "../images/allergens/3.png";
import img4 from "../images/allergens/4.png";
import img5 from "../images/allergens/5.png";
import img6 from "../images/allergens/6.png";
import img7 from "../images/allergens/7.png";
import img8 from "../images/allergens/8.png";
import img9 from "../images/allergens/9.png";
import img10 from "../images/allergens/10.png";
import img11 from "../images/allergens/11.png";
import img12 from "../images/allergens/12.png";
import img13 from "../images/allergens/13.png";
import img14 from "../images/allergens/14.png";

function Allergens({ allergens }) {
	const findImage = (allergenID) => {
		if (allergenID == 1) return img1;
		if (allergenID == 2) return img2;
		if (allergenID == 3) return img3;
		if (allergenID == 4) return img4;
		if (allergenID == 5) return img5;
		if (allergenID == 6) return img6;
		if (allergenID == 7) return img7;
		if (allergenID == 8) return img8;
		if (allergenID == 9) return img9;
		if (allergenID == 10) return img10;
		if (allergenID == 11) return img11;
		if (allergenID == 12) return img12;
		if (allergenID == 13) return img13;
		if (allergenID == 14) return img14;
		else return null;
	};
	return (
		<>
			{allergens.map((allergen, i) => (
				<div key={i}>
					<img src={findImage(allergen.id)} title={allergen.name} />
					<div>{allergen.name}</div>
				</div>
			))}
		</>
	);
}

export default Allergens;
