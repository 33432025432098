import { useContext } from "react";
import { MainContext } from "../App";
import "../css/Alert.css";

function Alert() {
	const { alertData, setAlertData } = useContext(MainContext);
	const handleConfirm = () => {
		if (typeof alertData.callback === "function") alertData.callback();
		setAlertData(false);
	};

	return (
		<div id="alert">
			<div className="content">
				<div className="title">{alertData.title}</div>
				<div className="message">{alertData.message}</div>
				<div className="buttons">
					{alertData.showCancelButton && (
						<button className="button danger" onClick={() => setAlertData(false)}>
							{alertData.cancelMessage ? alertData.cancelMessage : "No"}
						</button>
					)}
					<button className="button success" onClick={handleConfirm}>
						{alertData.confirmMessage ? alertData.confirmMessage : "Si"}
					</button>
				</div>
			</div>
		</div>
	);
}

export default Alert;
