import React, { useContext } from "react";
import { MainContext } from "../App";

function Menu() {
	const { loadMenuFromApi, setShop, user, setUser, setMenuVisible, setAlertData } =
		useContext(MainContext);

	return (
		<div className="menu">
			<div className="content">
				<div className="menu-header">
					<div>Ciao, {user.name}</div>
					<button
						className="btn btn-tertiary btn-square"
						onClick={() => setMenuVisible(false)}
					>
						<i className="fas fa-times"></i>
					</button>
				</div>

				<button
					onClick={() => {
						setShop(false);
						setMenuVisible(false);
					}}
				>
					<div className="icon">
						<i className="fas fa-store"></i>
					</div>
					Cambia Negozio
				</button>
				<button
					onClick={() => {
						setAlertData({
							title: "Sei sicuro?",
							message: "",
							showCancelButton: true,
							callback: async function () {
								setUser(false);
								setMenuVisible(false);
								localStorage.removeItem("waiter-user");
							},
						});
					}}
				>
					<div className="icon">
						<i className="fas fa-user"></i>
					</div>
					LogOut
				</button>
			</div>
		</div>
	);
}

export default Menu;
