import { useContext } from "react";
import { MainContext } from "../App";

export default function Footer() {
	const { table, setCartVisible, setPopupVisible, lockTable, cart } = useContext(MainContext);

	const getProductsCount = () => {
		let t = 0;
		for (const p of cart.products) {
			t += parseInt(p.qty);
		}
		return t;
	};

	return (
		<footer>
			{table?.status == 1 ? (
				<button
					onClick={() => setPopupVisible("history")}
					className="btn btn-secondary btn-block btn-icon"
				>
					<div className="badge">{table.orders_count}</div>
					Ordini
				</button>
			) : (
				<button className="btn btn-tertiary btn-block">Ordini</button>
			)}
			{table?.status == 1 ? (
				<button onClick={() => lockTable()} className="btn btn-secondary btn-block">
					Richiedi conto
				</button>
			) : (
				<button className="btn btn-tertiary btn-block">Richiedi conto</button>
			)}
			<button
				onClick={() => setCartVisible(true)}
				className="btn btn-secondary btn-block btn-icon"
			>
				<div className="badge">{getProductsCount()}</div>
				<i className="fa fa-shopping-cart" />
			</button>
		</footer>
	);
}
