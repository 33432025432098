import React, { useContext } from "react";
import { MainContext } from "../App";

function Shops() {
	const { shops, setShop } = useContext(MainContext);

	return (
		<div className="popup full-back">
			<div className="container shops-list">
				<header>
					<div className="title">Seleziona il negozio</div>
				</header>
				<div className="content">
					{shops &&
						shops.map((shop, i) => (
							<button onClick={() => setShop(shop)} key={i} className="list-button">
								{shop.name}
							</button>
						))}
				</div>
			</div>
		</div>
	);
}

export default Shops;
