import React, { useContext } from "react";
import { MainContext } from "../App";

export default function QrPopup() {
	const { tempTable, setQrPopupVisible } = useContext(MainContext);

	return (
		<div className="popup">
			<div className="container">
				<header>
					<div className="buttons-group">
						<button
							className="btn btn-tertiary btn-square"
							onClick={() => setQrPopupVisible(false)}
						>
							<i className="fas fa-times"></i>
						</button>
						<div>
							<div>{tempTable.name}</div>
							<div className="extrasmall">{tempTable.room.name}</div>
						</div>
					</div>
				</header>
				<div className="content qr-code-container">
					<div className="pin">
						<div>PIN</div>
						<div>{tempTable.pin}</div>
					</div>
					<img
						src={
							"https://api.yellgo.cloud/class/barcode.php?f=png&s=qr&w=500&h=500&d=" +
							tempTable.url
						}
					/>
					<div className="extrasmall">{tempTable.url}</div>
				</div>
			</div>
		</div>
	);
}
